import { API } from "@/apps/core/api";
import ViewModel from "@/apps/core/models/viewModel";
import { Diagnosis } from "@/apps/penyakit/models/diagnosis";
import { AktivitasKlinis } from "./aktivitasKlinis";
import { AktivitasKomponen } from "./aktivitasKomponen";


export class AktivitasDiagnosis extends AktivitasKomponen {
  utama: boolean | null = null;
  waktuVerifikasi: string | null = null;
  verifikasi: string | null = null;

  loadData(json: Record<string, any>) {
    const aktivitasKlinis = new AktivitasKlinis();
    const diagnosis = new Diagnosis();
    diagnosis.loadData(json.diagnosis);
    if (diagnosis.kode) {
      diagnosis.nama = `${diagnosis.kode} - ${diagnosis.nama}`;
    }
    aktivitasKlinis.loadData(json);
    json.aktivitas = aktivitasKlinis;
    json.diagnosis = diagnosis;
    super.loadData(json);
  }
}

export class AktivitasDiagnosisAPI extends API {
  static modelPath = "/aktivitas/diagnosis/";

  constructor() {
    super(AktivitasDiagnosisAPI.modelPath);
  }
}

export default class AktivitasDiagnosisVM extends ViewModel {
  constructor() {
    super(new AktivitasDiagnosisAPI(), new AktivitasDiagnosis());
  }
}
