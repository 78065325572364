



































































































import { defineComponent, ref } from "@vue/composition-api";
import { AktivitasDiagnosis } from "../models/aktivitasDiagnosis";
import AktivitasDiagnosisFM from "../models/aktivitasDiagnosisForm";
import { Diagnosis } from "@/apps/penyakit/models/diagnosis";
import router from "@/router";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "AktivitasDiagnosisForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    DiagnosisModalForm: () =>
      import("@/apps/penyakit/components/DiagnosisModalForm.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isDiagnosisModalFormActive = ref(false);
    const namaKomponen = ref("");
    const form = new AktivitasDiagnosisFM(["diagnosis"], ["utama"]);
    const paramsId = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const aktivitasDiagnosis = composition.instance as AktivitasDiagnosis;
    const formatOption = (opt: Record<string, string>) => {
      if (opt.kode !== "") {
        opt.nama = opt.kode + " - " + opt.nama;
      }
      return opt;
    };

    const setDiagnosis = (diagnosis?: Diagnosis) => {
      aktivitasDiagnosis.diagnosis = diagnosis ?? aktivitasDiagnosis.diagnosis;
      composition.validate("diagnosis");
      isDiagnosisModalFormActive.value = false;
    };
    return {
      // Data
      isDiagnosisModalFormActive,
      aktivitasDiagnosis,
      namaKomponen,
      formatOption,

      // Composition
      ...composition,

      // Method
      setDiagnosis,
    };
  },
});
