
































import axios from "axios";
import { computed, defineComponent } from "@vue/composition-api";
import { ToastProgrammatic as Toast } from "buefy";
import AktivitasDiagnosisVM, {
  AktivitasDiagnosis,
} from "@/apps/aktivitas/models/aktivitasDiagnosis";
import APP_CONFIG from "@/apps/core/modules/config";
import router from "@/router";
import { toUserDateTimeFormat } from "@/apps/core/modules/utils/datetime";
import useViewModel from "@/apps/core/modules/useViewModel";
import { setRefreshRequest as setAktivitasRefreshRequest } from "@/apps/aktivitas/modules/stores/aktivitas";
import { setRefreshRequest as setPerawatanRefreshRequest } from "@/apps/perawatan/modules/stores/perawatan";

export default defineComponent({
  name: "AktivitasDiagnosisDetail",
  props: {
    headerText: { type: String, required: true },
  },
  components: {
    AktivitasKomponenDetail: () =>
      import("@/apps/aktivitas/views/AktivitasKomponenDetail.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const aktDiagnosisVM = new AktivitasDiagnosisVM();
    const { viewModelRef, instance } = useViewModel(paramsId, aktDiagnosisVM);

    const aktKomp = instance as AktivitasDiagnosis;

    const verifikasiAktivitas = async () => {
      const apiUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/diagnosis/${paramsId}/`;
      const data = { verifikasi: aktKomp.verifikasi };
      try {
        axios.patch(apiUrl, data);
        Toast.open("Data berhasil di simpan.");
        setAktivitasRefreshRequest("context-menu");
        setPerawatanRefreshRequest("context-menu");
      } catch {
        Toast.open("Data gagal di simpan.");
      }
    };

    const canVerifikasi = computed(
      () => aktKomp.extraData && aktKomp.extraData.canVerifikasi
    );
    return {
      // Data
      aktKomp,
      toUserDateTimeFormat,
      viewModelRef,

      // Computed
      canVerifikasi,

      // Method
      verifikasiAktivitas,
    };
  },
});
