import FormModel from "@/apps/core/models/formModel";
import router from "@/router";
import {
  AktivitasDiagnosis,
  AktivitasDiagnosisAPI,
} from "./aktivitasDiagnosis";
import { setRefreshRequest as setAktivitasRefreshRequest } from "@/apps/aktivitas/modules/stores/aktivitas";
import { setRefreshRequest as setPerawatanRefreshRequest } from "@/apps/perawatan/modules/stores/perawatan";

const setRefreshRequest = (
  requestBy: string,
  filterMap: Record<string, string> | undefined
) => {
  setAktivitasRefreshRequest(requestBy, filterMap);
  setPerawatanRefreshRequest(requestBy, filterMap);
};

export default class AktivitasDiagnosisFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new AktivitasDiagnosisAPI(),
      new AktivitasDiagnosis(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  getPayload() {
    const payload = super.getPayload();
    payload.aktivitas = router.currentRoute.params.aktivitasId;
    // if (payload.utama === null) payload.utama = false;
    return payload;
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
